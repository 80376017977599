import '../../App.css'
import HeroSection from "../HeroSection";
import LeaderBoard from "../LeaderBoard";


function Home() {
    return (
        <>
            <HeroSection/>
            <LeaderBoard/>
        </>
    )
}

export default Home;