import '../../App.css'
import LeaderBoard2021 from "../Leaderboard2021";


function Erelijst() {
    return (
        <>
            <LeaderBoard2021/>
        </>
    )
}

export default Erelijst;