import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/storage';
import 'firebase/analytics'

//TODO:secure data

const config = {
    apiKey: "AIzaSyD_SiuEZKsap-awSE6F_eOWUrbbCxj5wWM",
    authDomain: "bots-bffc9.firebaseapp.com",
    projectId: "bots-bffc9",
    storageBucket: "bots-bffc9.appspot.com",
    messagingSenderId: "75658995580",
    appId: "1:75658995580:web:1403cfe3b80e4248e6e6f9",
    measurementId: "G-3R4J41GDLF"
};
var fb = null;
if (firebase.apps.length === 0) {
    fb = firebase.initializeApp(config);
    firebase.analytics();
} else {
    console.log('Firebase [Auth] is already Initialized');
}

export default fb;