import React, {useEffect} from 'react';
import CardItem from "./CardItem";
import './Cards.css'
import {Card, CardActionArea, CardContent, CardMedia, Grid, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import FirebaseService from "../services/firebaseService";
import {Link} from "react-router-dom";

const useStyles = makeStyles({
    root: {
        backgroundColor: '#269F92',
    },
    media: {
        height: 180,
    },

});

function Cards(props) {
    const classes = useStyles();
    const [clubs, setClubs] = React.useState([]);

    useEffect(() => {
        FirebaseService.getSortedLeaderBoard('random').then((clubs) => {
            setClubs(clubs);
        });
    }, []);

    return (
        <div className='cards'>
            <h1 className={'title'}>Check hieronder alle deelnemende verenigingen</h1>

            <div className={'cards__container'}>

                <Grid container spacing={5} justify={'center'}>
                    {clubs.map(function (club, index) {
                        console.log(club.logoUrl)
                        return (
                            <Grid item xs={12} sm={6} md={4}>
                                <Card className={classes.root}>
                                    <Link to={'/club/' + club.id} style={{textDecoration:"none"}}>
                                        <CardMedia
                                            className={classes.media}
                                            image={club.logoUrl}
                                            title={club.name.toString() + ' Logo'}
                                        />
                                        <CardContent>
                                            <CardContent>
                                                <Typography gutterBottom variant="h4" component="h2">
                                                    {club.name}
                                                </Typography>
                                            </CardContent>
                                        </CardContent>
                                    </Link>
                                </Card>
                            </Grid>
                        )
                    })}
                </Grid>
            </div>
        </div>
    );
}

export default Cards;