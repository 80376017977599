import React, {useEffect, useState} from 'react';
import {useParams} from "react-router";
import FirebaseService from "../services/firebaseService";
import {Typography} from "@material-ui/core";
import './ClubInfo.css'

function ClubInfo() {
    const { id } = useParams();

    const [club, setClub] = useState({name: 'club', infoText: ''})

    useEffect(async () => {
        let club = await FirebaseService.getClub(id);
        console.log(club);
        setClub(club)
    }, [])

    return (
        <div className={'clubInfo'}>
            <div className="container">
                <Typography variant={'h3'}>{club.name}</Typography>
                <div dangerouslySetInnerHTML={{__html: club.infoText}}/>
            </div>
        </div>
    );
}

export default ClubInfo;