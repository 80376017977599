import React, {useEffect} from 'react';
import {FormControl, FormControlLabel, Grid, LinearProgress, Radio, RadioGroup, Typography} from "@material-ui/core";
import {makeStyles} from '@material-ui/core/styles';
import './LeaderBoard.css'
import FirebaseService from "../services/firebaseService";
import {withStyles} from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    progressbar: {
        height: 40,
        borderRadius: 25
    }
}));

const BorderLinearProgress = withStyles((theme) => ({
    root: {
        height: 40,
        borderRadius: 25,
    },
    colorPrimary: {
        backgroundColor: '#8DA192',
    },
    bar: {
        borderRadius: 25,
        backgroundColor: '#269F92',
    },
}))(LinearProgress);

function LeaderBoard() {
    const classes = useStyles();
    const [sport, setSport] = React.useState('fietsen');
    const [clubs, setClubs] = React.useState([]);
    const [best, setBest] = React.useState(0);

    const handleChange = (event) => {
        setSport(event.target.value);
    };

    useEffect(() => {
        FirebaseService.getSortedLeaderBoard('fietsen').then((clubs) => {
            console.log(clubs);
            setClubs(clubs)
        })
    }, []);

    useEffect(() => {
        setClubs(Array.from(FirebaseService.sortClubs(clubs, sport)))
    }, [sport]);

    useEffect(() => {
        let bestValue;
        if (clubs.length !== 0) {
            switch (sport) {
                case 'fietsen':
                    bestValue = clubs[0].rideKm;
                    break;
                case 'lopen':
                    bestValue = clubs[0].runKm;
                    break;
                case 'total':
                    bestValue = clubs[0].total;
                    break;
                case 'totalpermember':
                    bestValue = clubs[0].totalPerMember;
                    break;
                case 'ski':
                    bestValue = clubs[0].skiKm;
                    break;    
                default:
                    bestValue = clubs[0].rideKm;
                    break;
            }
        } else {
            bestValue = 0;
        }
        setBest(bestValue);
    }, [clubs]);

    return (
        <div className='leaderBoard'>
            <Grid container spacing={3}>
                <Grid item xs={12} md={3}>
                    <Typography variant='h4'>Competitie</Typography>
                    <Typography variant='subtitle1'>Kies hier voor welke sport je het leaderboard wilt
                        bekijken</Typography>
                    <FormControl component="fieldset" color='text'>
                        <RadioGroup aria-label="gender" name="gender1" value={sport} onChange={handleChange}>
                            <FormControlLabel value="fietsen" control={<Radio/>} label="Fietsen" aria-selected='true'/>
                            <FormControlLabel value="lopen" control={<Radio/>} label="Lopen/Wandelen"/>
                            <FormControlLabel value="total" control={<Radio/>} label="Totaal"/>
                            <FormControlLabel value="ski" control={<Radio/>} label="Ski"/>
                            <FormControlLabel value="totalpermember" control={<Radio/>} label="Gemiddeld KM per lid"/>
                        </RadioGroup>
                    </FormControl>
                </Grid>
                <Grid item xs={12} md={9}>
                    <Grid container spacing={3}>
                        {clubs.map(function (club, index) {
                            let clubKm;
                            switch (sport) {
                                case 'fietsen':
                                    clubKm = clubs[index].rideKm;
                                    break;
                                case 'lopen':
                                    clubKm = clubs[index].runKm;
                                    break;
                                case 'total':
                                    clubKm = clubs[index].total;
                                    break;
                                case 'totalpermember':
                                    clubKm = clubs[index].totalPerMember;
                                    break;
                                case 'ski':
                                    clubKm = clubs[index].skiKm;
                                    break;
                                default:
                                    clubKm = clubs[index].rideKm;
                                    break;
                            }
                            return (
                                <>
                                    <Grid itam xs={12}><Typography variant={'h5'}>{index + 1 + ': ' + club.name}</Typography></Grid>
                                    <Grid item xs={12}>
                                        <div style={{position: "relative"}}>
                                            <BorderLinearProgress
                                                className={classes.progressBar}
                                                variant="determinate"
                                                value={Math.round((clubKm / best) * 100)}
                                            />
                                            <Typography
                                                style={{
                                                    position: "absolute",
                                                    color: "#ffffff",
                                                    top: 10,
                                                    left: "50%",
                                                    transform: "translateX(-50%)",
                                                }}
                                            >
                                                {clubKm}
                                            </Typography>
                                        </div>
                                    </Grid>
                                </>
                            )
                        })}
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
}

export default LeaderBoard;