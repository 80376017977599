import React from 'react';
import './Footer.css';
import { Link } from 'react-router-dom';
import {Typography} from "@material-ui/core";

function Footer() {
    return (
        <div className='footer-container'>
            <section className='social-media'>
                <img src={'/battleOfTheStudents.png'} alt={'Footer image'} className={'footer-image'}/>
                <div className='social-media-wrap'>
                    <div className='footer-logo'>
                        <Link to='/' className='social-logo'>
                            BOTS2560 <i className="fab fa-strava"/>
                        </Link>
                    </div>
                    <small className='website-rights'>BOTS2560 @ 2022</small>
                    <div className='social-icons'>
                        <Typography variant={"body1"}>Developed By Toby And Robbe</Typography>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default Footer;