import React from 'react';
import {Button} from "./Button";
import '../App.css'
import './HeroSection.css';

function HeroSection() {
    return (
        <div className='hero-container'>
            {/*<video src="https://www.youtube.com/embed/iDEXa5zoc3Q?rel=0&autoplay=1" autoPlay loop muted />
                <iframe src="https://www.youtube.com/embed/iDEXa5zoc3Q?rel=0&autoplay=1&mute=1&loop=1&controls=0"
                        frameBorder="0" allowFullScreen/>*/}
            <h1>#BOTS2560</h1>
            <p>Elke kilometer telt</p><p>Beweeg jouw vereniging naar de overwinning!</p>
            <div className="hero-btns">
                <Button className='btns' buttonStyle='btn--outline' buttonSize='btn--large'
                        onClick='/clubs'>CLUBS</Button>
            </div>
        </div>
    );
}

export default HeroSection;