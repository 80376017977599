import './App.css';
import Navbar from "./components/Navbar";
import {BrowserRouter as Router, Switch, Route} from "react-router-dom";
import Home from "./components/pages/Home";
import Footer from './components/Footer'
import Clubs from "./components/pages/Clubs";
import Info from "./components/pages/Info";
import Club from "./components/pages/Club"
import Erelijst from './components/pages/Erelijst';

import {makeStyles, ThemeProvider} from "@material-ui/styles";
import {createMuiTheme} from "@material-ui/core";

const theme = createMuiTheme({
    typography: {
        allVariants: {
            color: "#ffffff"
        }
    },
    palette: {
        //primary: '#FC6100',
        text: '#ffffff'
    },
    overrides: {
        MuiRadio: {
            root: {
                color: '#FC6100'
            },
            colorSecondary: {
                '&$checked': {
                    color: '#FC6100',
                },
            },
        }
    }
})

const useStyles = makeStyles({})

function App() {
    const classes = useStyles();
    return (
        <>
            <ThemeProvider theme={theme}>
                <Router>
                    <div className="box">
                        <div className="header">
                            <Navbar/>
                        </div>
                        <div className="content">
                            <Switch>
                                <Route path='/' exact component={Home}/>
                                <Route path='/erelijst' component={Erelijst}/>
                                <Route path='/clubs' component={Clubs}/>
                                <Route path='/info' component={Info}/>
                                <Route path='/club/:id' component={Club}/>
                            </Switch>
                        </div>
                        <div className="footer">
                            <Footer/>
                        </div>
                    </div>
                </Router>
            </ThemeProvider>
        </>
    );
}

export default App;
