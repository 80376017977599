import React from 'react'
import '../ClubInfo.css'
import {Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles({
    indent: {
        marginLeft: '40px',
    }
});

export default function Info() {
    const classes = useStyles();
    return (
        <div className={'clubInfo'}>
            <div className="container">
                <Typography variant={'h3'}>Wedstrijd Informatie en Regels</Typography>
                <br/>
                <Typography variant={'h5'}>Wat is de Battle of the Strava’s 2560?</Typography>
                <br/>
                <p>
                    Nu onze kinderen enkele weken weer fulltime thuis zitten en elkaar niet meer zien in de klas of op
                    de speelplaats, willen we ze toch motiveren om buiten te sporten. Daarom organiseren de Nijlense
                    jeugdverenigingen dit evenement. We willen zoveel mogelijk kinderen in beweging zetten.
                </p>
                <p>
                    Op deze website kan je zien hoeveel kilometers jouw jeugdvereniging heeft gelopen, gewandeld (samen
                    met lopen) of gefietst. De vereniging met de meeste kilometers in een bepaalde categorie staat aan
                    kop! Verdedig dus zeker jouw jeugdvereniging en ga coronaproof een toertje joggen, wandelen of
                    fietsen! Elke kilometer telt!
                </p>
                <p>
                    Iedereen kan deelnemen aan deze activiteit. Het enige dat je hoeft te doen, is lid worden van de
                    Stravaclub van je jeugdvereniging.
                </p>
                <br/>
                <Typography variant={'h5'}>Wanneer en hoe?</Typography>
                <br/>
                <ul>
                    <li>4 april – 17 april 2022</li>
                    <li>Word lid van de Stravaclub van jouw keuze. Ontdek <a href="http://www.bots2560.be/clubs">hier</a> alle deelnemende Stravaclubs.
                    </li>
                    <li>Test zeker nu al of je activiteiten worden geregistreerd in de club.</li>
                    <li>Elke activiteit die je registreert via Strava, komt automatisch in de Stravaclub van
                        de jeugdvereniging terecht en telt vervolgens via de website bij de kilometers van
                        de jeugdvereniging.
                    </li>
                    <li>Het is belangrijk dat de huidige coronamaatregelen ten alle tijden gerespecteerd
                        worden!
                    </li>
                </ul>
                <br/>
                <Typography variant={'h5'}>Competitie!</Typography>
                <br/>
                <ul>
                    <li>Alle kilometers worden geregistreerd op <a href="http://www.bots2560.be">www.bots2560.be</a>.
                        Hiervoor worden gegevens uit de verschillende Stravaclubs gebruikt.
                    </li>
                    <li>Er zijn rankings op verschillende niveau’s:</li>
                    <ul className={classes.indent}>
                        <li>Lopen en wandelen</li>
                        <li>Fietsen</li>
                        <li>Skiën en snowboarden</li>
                        <li>Totaal</li>
                        <li>Gemiddeld KM per lid</li>
                    </ul>
                </ul>
                <br/>
                <p>
                    PS: Het kan tot 15 minuten duren voor je activiteit bij in het leaderboard komt te staan.
                </p>
            </div>
        </div>
)
}