import fb from '../firebase/firebaseConfig';

function compareValues(key, order = 'asc') {
    return function innerSort(a, b) {
        if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
            // property doesn't exist on either object
            return 0;
        }

        const varA = (typeof a[key] === 'string')
            ? a[key].toUpperCase() : a[key];
        const varB = (typeof b[key] === 'string')
            ? b[key].toUpperCase() : b[key];

        let comparison = 0;
        if (varA > varB) {
            comparison = 1;
        } else if (varA < varB) {
            comparison = -1;
        }
        return (
            (order === 'desc') ? (comparison * -1) : comparison
        );
    };
}

function shuffle(arr) {
    var len = arr.length;
    var d = len;
    var array = [];
    var k, i;
    for (i = 0; i < d; i++) {
        k = Math.floor(Math.random() * len);
        array.push(arr[k]);
        arr.splice(k, 1);
        len = arr.length;
    }
    for (i = 0; i < d; i++) {
        arr[i] = array[i];
    }
    return arr;
}

export default class FirebaseService {

    static async getSortedLeaderBoard(sortCategory, collectionName = 'Clubs') {
        let storage = fb.storage();
        let clubs = [];
        await fb.firestore().collection(collectionName).get()
            .then((querySnapchot) => {
                querySnapchot.forEach((doc) => {
                    let club = doc.data();
                    club.name = doc.id;
                    clubs.push(club);
                })
            })
            .catch((error) => {
                console.log("Error getting clubs: ", error);
            });
        for (const club of clubs) {
            let logoRef = storage.ref(club.logo);
            club.logoUrl = await logoRef.getDownloadURL();
        }
        clubs = Array.from(this.sortClubs(clubs, sortCategory))
        return clubs;
    }

    static sortClubs(clubs, sortCategory) {
        switch (sortCategory) {
            case 'fietsen':
                clubs.sort(compareValues('rideKm', 'desc'))
                break;
            case 'lopen':
                clubs.sort(compareValues('runKm', 'desc'))
                break;
            case 'total':
                clubs.sort(compareValues('total', 'desc'))
                break;
            case 'ski':
                clubs.sort(compareValues('skiKm', 'desc'))
                break;
            case 'random':
                clubs = shuffle(clubs);
                break;
            case 'totalpermember':
                clubs.sort(compareValues('totalPerMember', 'desc'))
                break;
            default:
                clubs.sort(compareValues('rideKm', 'desc'))
                break;
        }
        return clubs;
    }

    static async getClub(id) {
        let club;

        await fb.firestore().collection('Clubs').where("id", "==", parseInt(id))
            .get()
            .then((querySnapchot) => {
                console.log(id)
                club = querySnapchot.docs[0].data();
                club.name = querySnapchot.docs[0].id;
            })
        return club;
    }
}